import React, { useState, useEffect, useRef } from 'react';
import AxiosInstance from "./../services/axiosInstance";
import { useHistory } from "react-router-dom"
import { Form, Input, Select, Button, DatePicker, TimePicker, Row, Col, Modal, Checkbox } from 'antd';
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Toaster } from "../utility/Toaster";
import moment from 'moment';
import dayjs from 'dayjs';
import { Message } from '../utility/MessageUtil';
import { RefreshComponet } from "../utility/UserDetails";
import fetchGeolocation from "../utility/CurrentLocation";
import { FormattedMessage } from 'react-intl';
import { useTranslation } from "react-i18next";


const { Option } = Select;
const { confirm } = Modal;



//   {
//     "name": "test Input",
//     "label": "Test Input",
//     "type": "input",
//     "placeholder": "text",
//     "inputType":"number",
//     "value":"",
//     "required": false
//   },
// {
//     "name": "test",
//     "label": "Test",
//     "placeholder": "options",
//     "type": "select",
//     "options":[{label:'test',value:'1'},{label:'test2',value:'2'}],
//     "required": false
//   }


const ManualEntry = () => {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const redirectSchedule = useHistory();
  const { t } = useTranslation();
  const state = localStorage.getItem("state") || "";
  const userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
  const ServiceTitle = userInfo?.title?.name
  const Formfield = [
    {
      "name": "sDate",
      "label": t("SelectDate"),
      "type": "date",
      "min_date": state === 'IL' ? moment(new Date()).subtract(1, "day") : {},
      "max_date": {},
      "value": "",
      "placeholder": t("SelectDate"),
      "required": true
    },
    {
      "name": "clientId",
      "label": t("Client"),
      "type": "select_client",
      "placeholder": t("SelectClient"),
      "required": true
    },
    {
      "name": "auth",
      "label": t("Service"),
      "type": "select_service",
      "placeholder": t("Select Service"),
      "required": true
    },
    {
      "name": "sTime",
      "label": t("Time"),
      "type": "time",
      "placeholder": t("Select Time"),
      "value": "",
      "required": true
    },
  ]
  const [fields, setFields] = useState(Formfield);
  const [clientList, setclientList] = useState([]);
  const [clientServices, setClientServices] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});
  const [formValues, setFormValues] = useState({});
  const [serviceDesc, setServiceDesc] = useState("");
  const [serviceDetails, setServiceDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [actualPosition, setactualPosition] = useState({ latitude: null, longitude: null });
  const [isChecked, setIsChecked] = useState(false);
  const [isCommunictyChecked, setIsCommunictyChecked] = useState(false);
  const latestIsChecked = useRef(isChecked);
  const latestIsCommunictyChecked = useRef(isCommunictyChecked);
  const [ratio, setRatio] = useState(0); // Extract initial number
  const [selectedClients, setSelectedClients] = useState(new Set()); // State to track selected clients

  useEffect(() => {
    latestIsChecked.current = isChecked;
  }, [isChecked]);

  useEffect(() => {
    latestIsCommunictyChecked.current = isCommunictyChecked;
  }, [isCommunictyChecked]);

  useEffect(() => {
  }, [serviceDetails]);


  useEffect(() => {
    form.resetFields();
    const url = `emp/my-client`;
    AxiosInstance.get(url)
      .then(res => {
        if (res.data.dirtyBit) RefreshComponet(res.data.dirtyBit)
        const clients = res.data.data;
        setclientList(clients);
        if (clients.length === 1) {
          form.setFieldsValue({ clientId: clients[0]._id });
          // setFormValues({clientId:clients[0]._id,sDate: dayjs(today, 'DD/MM/YYYY').format('YYYY/MM/DD')});
          handleClientChange(clients[0]._id);
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
    const today = moment(new Date()).format('DD/MM/YYYY');
    const time = moment(new Date()).format('hh:mm A');
    form.setFieldsValue({ sDate: dayjs(today, 'DD/MM/YYYY'), sTime: dayjs(time, 'hh:mm A') });
  }, []);

  useEffect(() => {
    if (formValues.sDate && formValues.clientId) handleClientChange();
  }, [formValues.sDate, formValues.clientId]);

  useEffect(() => {
    // if (clientServices.length === 1) {
    if (form.getFieldValue('auth')) handleServiceChange(form.getFieldValue('auth'));
    // form.setFieldsValue({ auth: clientServices[0]._id });
    // }
  }, [formValues.auth]);

  useEffect(() => {
    fetchGeoloc();
    setRatio(0);
    setServiceDesc("");
    setSelectedClients(new Set());
  }, []);

  const fetchGeoloc = async () => {
    try {
      const location = JSON.parse(localStorage.getItem("location"));

      if (location) {
        setPosition({
          latitude: parseFloat(location.lat),
          longitude: parseFloat(location.long),
        });
        setactualPosition({
          latitude: parseFloat(location.lat),
          longitude: parseFloat(location.long),
        });
      } else {
        const fetchedPosition = await fetchGeolocation();
        setPosition({
          latitude: fetchedPosition.latitude,
          longitude: fetchedPosition.longitude,
        });
        setactualPosition({
          latitude: fetchedPosition.latitude,
          longitude: fetchedPosition.longitude,
        });
      }
    } catch (error) {
      Toaster(
        "error",
        "Error fetching geolocation:",
        error.message
      );
      //   console.error("Error fetching geolocation:", error.message);
    }
  };

  const handleFormChange = (changedValues, allValues) => {
    if (allValues.sDate && allValues.sDate.$d) allValues.sDate = dayjs(allValues.sDate.$d).format('YYYY-MM-DD');
    setFormValues(prevState => ({ ...prevState, ...changedValues }));
    console.log(allValues,"allValues")
    // if (allValues.auth) setFormValues(allValues);
    // if (changedValues.sDate) 
  };

  const extractRatio = (description) => description?.split(':')[1];
  const handleClientChange = () => {
    const sDate = dayjs(form.getFieldValue('sDate').$d).format('YYYY-MM-DD');
    const clientId = form.getFieldValue('clientId') || formValues.clientId;
    if ((formValues.clientId && formValues.sDate) || (sDate && clientId)) {
      handleReset("auth");
      setClientServices([]);
      setServiceDetails({});
      const url = `client/service-agreement-dates?client_id=${clientId || formValues.clientId}&date=${sDate || formValues.sDate}`;
      AxiosInstance.get(url)
        .then(res => {
          if (res?.data?.success) {
            const services = res?.data?.data;
            setClientServices(services);
            if (services?.length === 1) {
              debugger
              const service = services[0];
              if (serviceDesc && selectedClients.size !== 0) {
                const prevRatio = extractRatio(serviceDesc);
                const currRatio = extractRatio(service.service.desc);
                if (prevRatio !== currRatio) {
                  Toaster("error", t("Sorry, the ratios do not match"));
                  return;
                }
              }
              handleService(service);
              //   if(serviceDesc){
              //     const prvRatio = serviceDesc.split(':')[1];
              //     const currRatio = services[0].service.desc.split(':')[1];;
              //     if (prvRatio !== currRatio) {
              //       Toaster("error","sorry")
              //       return;
              //     }
              //     }
              // setFormValues(prevState => ({...prevState,auth:services[0]._id}));
              // form.setFieldsValue({ auth: services[0]._id });
              // handleServiceChange(services[0]._id,services);
            }
            else {
              if (ServiceTitle && selectedClients.size == 0) {
                // Filter services by ServiceTitle
                const filteredServices = res?.data?.data.filter((data) => data?.service?.desc.includes(ServiceTitle));

                if (filteredServices?.length) {
                  handleService(filteredServices[0], services);
                }
              } else if (serviceDesc && selectedClients.size !== 0) {
                // Filter services by service description
                const filteredServices = res?.data?.data.filter((data) => data?.service?.desc.includes(serviceDesc));

                if (filteredServices?.length) {
                  const prevRatio = extractRatio(serviceDesc);
                  const currRatio = extractRatio(filteredServices[0].service.desc);

                  if (prevRatio !== currRatio) {
                    Toaster("error", t("Sorry, the ratios do not match"));
                    return;
                  }

                  handleService(filteredServices[0], services);
                } else {
                  Toaster("error",t("No Service Available with the Same Ratio"));
                  handleReset(''); 
                  setSelectedClients(new Set());
                  setServiceDesc("");
                  return;
                }
              }

              // if (ServiceTitle && !serviceDesc) {
              //   const filteredArray = res?.data?.data.filter((data) => data?.service?.desc.includes(ServiceTitle));
              //   if (filteredArray?.length) {
              //     setFormValues(prevState => ({ ...prevState, auth: filteredArray[0]._id }));
              //     form.setFieldsValue({ auth: filteredArray[0]._id });
              //     handleServiceChange(filteredArray[0]._id, services);
              //   }
              // } else {
              //   if (serviceDesc) {
              //     const filteredArray = res?.data?.data.filter((data) => data?.service?.desc.includes(serviceDesc));
              //     if (filteredArray?.length) {
              //       const prvRatio = serviceDesc.split(':')[1];
              //       const currRatio = filteredArray[0].service.desc.split(':')[1];;
              //       if (prvRatio !== currRatio) {
              //         Toaster("error", "sorry")
              //         return;
              //       }
              //       setFormValues(prevState => ({ ...prevState, auth: filteredArray[0]._id }));
              //       form.setFieldsValue({ auth: filteredArray[0]._id });
              //       handleServiceChange(filteredArray[0]._id, services);
              //     } else {
              //       Toaster("error", "no SA with same ratio")
              //       return;
              //     }
              //   }

              // }
            }
          } else {
            setClientServices([]);
            form.setFieldsValue({ auth: "" });
          }



          // if (res.data.data.length == 1) {
          //   setFormValues(prevState => ({...prevState,auth:res.data.data[0]._id}));
          //   form.setFieldsValue({ auth: res.data.data[0]._id });
          //   handleServiceChange(res.data.data[0]._id);
          // }
          // if (res.data.success) setClientServices(res.data.data);
          // else setClientServices([]);
        })
        .catch(error => {
          console.error('Error fetching services:', error);
        });
    }
  }
  // Function to handle service change logic
  const handleService = (service, services) => {
    setFormValues(prevState => ({ ...prevState, auth: service._id }));
    form.setFieldsValue({ auth: service._id });
    handleServiceChange(service._id, services);
  };
  const handleServiceChange = (value, data) => {
    const val = clientServices?.find(service => service._id === value);
    if (val) {
      console.log(val, "value")
      // form.setFieldsValue({ auth: val._id });
      setServiceDetails(val);
      if (val.service.desc && selectedClients.size === 0) {
        if (val.service.desc.includes("1:")) {
          const valueAfterColon = val.service.desc.match(/:(\d+)/)[1];
          setServiceDesc(val.service.desc);
          if (valueAfterColon) {
            const number = Number(valueAfterColon);
            setRatio(number);
            console.log(ratio, number)
          }
        }
        else setServiceDesc("");
        // const valueAfterColon = val.service.desc.split(':')[1];
        // if(valueAfterColon){
        // const number = Number(valueAfterColon);
        // setRatio(number);
        // console.log(ratio,number)
        // }
      }
      const sDate = new Date(val.sDate);
      sDate.setUTCHours(12);
      const eDate = new Date(val.eDate);
      eDate.setUTCHours(12);
      if (sDate > new Date(formValues.sDate) && eDate < new Date(formValues.sDate)) {
        handleReset("auth");
        setServiceDetails({});
      }
    } else {
      // setServiceDetails({});
    }
  };


  const onCheckWarning = async (values) => {
    if (!position.latitude) {
      Toaster("error", "Error", t("Your device is unable to pick your location, Please check your device settings"));
      return
    }
    setLoading(true);
    let obj = {
      auth: serviceDetails._id,
      lat: position.latitude,
      lng: position.longitude,
      clientId: serviceDetails?.client_id,
    };
    // }
    if (serviceDetails) {
      const endPoint = "sch-start/check";
      AxiosInstance.put(endPoint, obj)
        .then(({ data }) => {
          setLoading(false);
          if (!data.success) {
            if (data?.message === "Auth doesn't Exists.") {
              Message.error(data?.message)
            }
            const allInactive = data?.addressList?.every(location => !location.active);
            // const onleGeoFence = data?.message?.length === 1;
            if (allInactive) {
              if (data?.message?.length > 0) {
                checkWarningModal(data, values,);
              } else {
                onFinish(values);
              }

            }
            else {
              checkWarningModal(data, values,);
            }
          } else {
            onFinish(values);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
        }); // onScheStartClick(obj,type);
    }
  }

  const onChange = (value, option) => {
    console.log(`selected ${value}`);
    const lat = option.lat;
    const lng = option.lng;
    console.log("Selected Latitude:", lat);
    position.latitude = lat;
    position.longitude = lng;
    console.log("Selected Longitude:", lng);
  };

  const checkWarningModal = (message, obj,) => {
    const allInactive = message?.addressList?.every(location => !location.active);
    message?.message[0].message === "You are out of the Geo Fence. Please contact support."
    || message?.message[0].message === "The Geo Coordinates doesn't exists for client address, Please contact your support"
      ? Modal.confirm({
        title: message?.message[0].message,
        confirmLoading: loading,
        icon: <ExclamationCircleFilled />,
        footer: (
          <div style={{ float: "inline-end" }}>
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => Modal.destroyAll()}
            >
              {t("OK")}
            </Button>
          </div>
        ),
      })
      : Modal.confirm({
        title: "",
        okText: t("Yes"),
        size: "small",
        confirmLoading: loading,
        // icon: <ExclamationCircleFilled />,
        content: (
          <div>
            {message?.message?.map((item, index) => (
              <div key={index}>
                {item?.data?.covidCheck && (
                  <span>
                    <Checkbox
                      onChange={(e) => setIsChecked(e.target.checked)}
                    ></Checkbox>{" "}
                  </span>
                )}{" "}
                {item?.data?.covidCheck && item.message}

                {item?.data?.communityService && (
                  <span>
                    <Checkbox
                      onChange={(e) => setIsCommunictyChecked(e.target.checked)}
                    ></Checkbox>{" "}
                  </span>
                )}{" "}
                {item?.data?.communityService && item.message}

                {!allInactive && item?.data?.warning && item.message}
                {allInactive && item?.data?.warning && item.message}
                {item?.data?.warning && !allInactive && message?.addressList?.length && index === 0 && (
                    <div style={{ marginBottom: 10 }}>
                      <div>
                        <label style={{ fontWeight: "500" }}>
                          {t("Please select a Location")}:{"        "}
                        </label>
                      </div>
                      <Select
                        showSearch
                        placeholder={t("Select a Location")}
                        optionFilterProp="children"
                        onChange={onChange}
                        style={{ width: "100%" }}
                      >
                        {message?.addressList?.map((location) => (
                          <Option
                            key={location.address}
                            value={location.address}
                            lat={location.lat}
                            lng={location.lng}
                            disabled={!location.active}
                          >
                            {location.address}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                {item?.data?.remoteService && item.message}
                {item?.data?.remoteService && (
                  <>
                    {validatePin(obj?.clientId)}
                    <div>
                      <label style={{ fontWeight: "500" }}>
                        {t("Please enter PIN sent to client's mobile number")}
                      </label>
                      <Input
                        placeholder="Enter PIN"
                        ref={inputRef}
                        maxLength={4}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ),
        onOk() {
          const remoteServiceData = message?.message.filter(
            (item) => item.data.remoteService === true
          )[0]?.data;
          const pinValue = inputRef?.current?.input?.value?.trim();
          if (remoteServiceData) {
            if (pinValue !== "") {
              onFinish(obj);
              Modal.destroyAll();
            } else {
              Message.error("Please Enter Pin");
            }
          } else {
            onFinish(obj);
            Modal.destroyAll();
          }
        },
        onCancel() {
          fetchGeoloc();
          Modal.destroyAll();
        },

        okButtonProps: {
          className: "ant-btn-modal",
        },

        cancelButtonProps: {
          className: "ant-btn-confirm-cancel",
        },
      });

  }

  const onFinish = async (values) => {
    setLoading(true);
    values.is_diagnosis = latestIsChecked.current || false;
    values.sDate = dayjs(values.sDate.$d).format('YYYY/MM/DD');
    values.eDate = values.sDate;
    values.sTime = dayjs(values.sTime.$d).format('hh:mm A');
    values.eTime = moment(values.sTime, 'hh:mm A').add(1, 'hours').format('hh:mm A');
    values.service = serviceDetails.service._id;
    values.cid = serviceDetails.companyId._id;
    values.lat = position.latitude;
    values.lng = position.longitude;
    values.actualLng = actualPosition.longitude;
    values.actualLat = actualPosition.latitude;
    values.communityService = latestIsCommunictyChecked.current || false;
    // }
    let updatedValues = {}
    const pinValue = inputRef?.current?.input?.value?.trim();
    if (pinValue) {
      if (pinValue?.length !== 4) {
        Message.info('Please enter 4 digit code');
        setLoading(false);
        return;
      }
      else {
        updatedValues = {
          ...values,
          remotePin: pinValue,
        };
      }
    }

    if (position.longitude && position.latitude) {

      const url = 'emp/addschedule';
      AxiosInstance.post(url, pinValue ? updatedValues : values)
        .then(res => {
          setLoading(false);
          if (res.data.success) {
            Toaster("success", "Success", res.data.message);
            handleReset();
            if (serviceDesc && clientList.length > 1) {
              const data = res.data.data.refScheduleId ? res.data.data.refScheduleId : res.data.data;
              setSelectedClients((prevSelected) => new Set(prevSelected).add(data.clientId._id));
              SAratioConfirmationModal(data);
            } else {
              // handleReset();
              redirectSchListing(res.data.data.refScheduleId ? res.data.data.refScheduleId : res.data.data)
            }
          } else {
            if (res?.data?.is_diagnosis) {
              Modal.confirm({
                title: res?.data?.message,
                confirmLoading: loading,
                icon: <ExclamationCircleFilled />,
                footer: (
                  <div style={{ float: 'inline-end' }}>
                    <Button key="submit" type="primary" loading={loading} onClick={() => Modal.destroyAll()}>
                      OK
                    </Button>
                  </div>),
              })
              fetchGeoloc();
              setIsChecked(false)
              setIsCommunictyChecked(false)
            }
            else {
              if (res?.data.message === "You have entered Invalid Pin") {
                Toaster("error", "Error", res?.data.message);
                fetchGeoloc();
                setIsChecked(false)
                setIsCommunictyChecked(false)
              } else {
                fetchGeoloc();
                Toaster("info", "Attention", res?.data.message);
              }
            }
          }
        })
        .catch(error => {
          setLoading(false);
          console.error("Error fetching data:", error);
        });
    }
    else {
      setLoading(false);
      Toaster("error", t("Your device is unable to pick your location, Please check your device settings"), "");
    }

  };
  const handleReset = (type = "") => {
    if (!type) {
      form.resetFields();
      setFormValues({});
      setServiceDetails({});
      setClientServices([]);
      const today = moment(new Date()).format('DD/MM/YYYY');
      const time = moment(new Date()).format('hh:mm A');
      form.setFieldsValue({ sDate: dayjs(today, 'DD/MM/YYYY'), sTime: dayjs(time, 'hh:mm A') });
    }
    else {
      setTimeout(() => {
        form.setFieldsValue({ auth: undefined });
      }, 100);
    }
  };

  const validatePin = (values) => {
    let endPoint = "client/pin-remote";
    AxiosInstance.post(endPoint, { clientId: values })
      .then(({ data }) => {
        if (data.success) {
          Message.success(data.message);
        } else {
          Toaster("error", "Error", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) => {
    // If option is a string, trim and lowercase it before checking
    if (typeof option === 'string') {
      return option.trim().toLowerCase().includes(input.trim().toLowerCase());
    }
    // If option has children, recursively call filterOption on each child
    if (option?.children && Array.isArray(option.children)) {
      return option.children.some(child => filterOption(input, child));
    }
    // Return false for non-string options and options without children
    return false;
  }
  const redirectSchListing = (data) => {
    const userDetails = JSON.parse(localStorage.getItem('USER_INFO'));
    redirectSchedule.push({
      pathname: "/ScheduleDetails",
      state: { selectedClient: data, userDetails: userDetails },
    });
  };
  const SAratioConfirmationModal = (data) => {
     if (ratio > 1) {
      const newNumber = ratio - 1; // Decrement the number
      setRatio(newNumber); // Update state with the new number
    Modal.confirm({
      title: t(`Press Yes to create additional schedules for the same selected ratio service with different clients`,{ newNumber: newNumber }),
      confirmLoading: loading,
      icon: <ExclamationCircleFilled />,
      footer: (
        <div style={{ float: "inline-end" }}>
          <Button
            key="submit"
            type="primary"
            danger
            loading={loading}
            style={{ marginRight: '10px' }}
            onClick={() => { Modal.destroyAll(); redirectSchListing(data) }}
          >
            {t("Cancel")}
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => { Modal.destroyAll() }}
          >
            {t("OK")}
          </Button>
        </div>
      ),
    });
    const today = moment(new Date()).format('DD/MM/YYYY');
    const time = moment(new Date()).format('hh:mm A');
    form.setFieldsValue({ sDate: dayjs(today, 'DD/MM/YYYY'), sTime: dayjs(time, 'hh:mm A') });
     }else{
       redirectSchListing(data);
     }
  }
  // const checkSARatio = () =>{
  //   const prvRatio = serviceDesc.split(':')[1];
  //   //const currRatio = 
  // }
  const renderFormField = (field) => {
    const { type, name, label, options, inputType, placeholder, min_date, max_date } = field;
    switch (type) {
      case 'date':
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[{ required: field.required, message: `Please select ${label}` }]}
          >
            <DatePicker placeholder={placeholder} format={'MM/DD/YYYY'}
              disabledDate={(current) => {
                return current && (current < min_date || current > max_date);
              }
              } size='large' />
          </Form.Item>
        );

      case 'select_client':
        return (
          <Form.Item
            label={label}
            name={name}
            rules={[{ required: field.required, message: `Please select ${label}` }]}
          >
            <Select size='large' placeholder={placeholder} onChange={handleClientChange} showSearch filterOption={filterOption} loading={clientList.length === 0}>
              {clientList.map(option => (
                <Option key={option._id} value={option._id} disabled={selectedClients.has(option._id)}>
                  {option.fName}  {option.lName} {option.evv_hrs && <span>({option.evv_hrs})</span>}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      case 'select_service':
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[{ required: field.required, message: `Please select ${label}` }]}
          >
            <Select size='large' placeholder={placeholder} disabled={selectedClients?.size}>
              {clientServices.map((service, index) => (
                <Option key={index} value={service._id}>
                  {dayjs(service.sDate).add(1, 'day').format('MM-DD-YYYY')} / {dayjs(service.eDate).add(1, 'day').format('MM-DD-YYYY')} / {service.pAuth} / {service?.service?.desc}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      case 'select':
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[{ required: field.required, message: `Please select ${label}` }]}
          >
            <Select placeholder={placeholder} size='large'>
              {options.map(option => (
                <Option key={option.value} value={option.value}>{option.label}</Option>
              ))}
            </Select>
          </Form.Item>
        );

      case 'time':
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[{ required: field.required, message: `Please select ${label}` }]}
          >
            <TimePicker disabled={selectedClients?.size} placeholder={placeholder} size='large' use12Hours hangeOnScroll needConfirm={false} format="hh:mm A" />
          </Form.Item>
        );
      case 'input':
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[{ required: field.required, message: `Please enter ${label}` }]}
          >
            <Input size='large' type={inputType} placeholder={placeholder} />
          </Form.Item>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Form className='manualEntry-info' onValuesChange={handleFormChange} form={form} onFinish={onCheckWarning} layout="vertical" style={{ padding: "0px 100px" }}>
        {/* <Row gutter={[16, 0]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Select Date"
              name="sDate"
              rules={[
                {
                  required: true,
                  message: "Please Select Date",
                },
              ]}
            >
              <DatePicker format={'MM/DD/YYYY'} size='large' />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Select Client"
              name="clientId"
              rules={[
                {
                  required: true,
                  message: "Please Select Client",
                },
              ]}
            >
              <Select size='large' placeholder="Please Select Client" showSearch filterOption={filterOption} loading={clientList.length === 0}>
                {clientList.map(option => (
                  <Option key={option._id} value={option._id}>
                    {option.fName}  {option.lName} {option.evv_hrs && <span>({option.evv_hrs})</span>}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Select Service"
              name="auth"
              rules={[
                {
                  required: true,
                  message: "Please Select Service",
                },
              ]}
            >
              <Select size='large' placeholder="Please Select Service" onChange={handleServiceChange} loading={clientServices.length === 0}>
                {clientServices.map(service => (
                  <Option key={service._id} value={service._id}>
                    {moment(service.sDate).format('MM-DD-YYYY')} {moment(service.eDate).format('MM-DD-YYYY')} {service.pAuth} {service?.service?.desc}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Select Time"
              name="sTime"
              rules={[
                {
                  required: true,
                  message: "Please Select Time",
                },
              ]}
            >
              <TimePicker size='large' use12Hours hangeOnScroll needConfirm={false} format="hh:mm A" />
            </Form.Item>
          </Col>
        </Row> */}

        <Row gutter={[16, 0]}>
          {Formfield.map((field, index) => (
            <Col key={index} xs={24} sm={12}>{renderFormField(field)}</Col>
          ))}
        </Row>
        {/* {serviceDetails?._id && serviceDetails?.isHssType && */}
        <Row gutter={[16, 0]}>
          {serviceDetails?._id && serviceDetails?.isHssType &&
            <>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={t("SelectHSSType")}
                  name="hssType"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Time",
                    },
                  ]}
                >
                  <Select size='large' placeholder="Select HSS Type" >
                    {serviceDetails.hssTypeList.map((x, index) => (
                      <Option key={index} value={x}>{x}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {formValues.hssType === "Remote Direct" && <Col span={12}>
                <Form.Item
                  label={t("SelectRemote")}
                  name="remoteDirectOptions"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Time",
                    },
                  ]}
                >
                  <Select size='large' placeholder="Select Remote Direct Options">
                    <Option value='Office'>Office</Option>
                    <Option value='Community'>Community</Option>
                    <Option value='Others'>Others</Option>
                  </Select>

                </Form.Item>
              </Col>
              }
              {serviceDetails?._id && serviceDetails?.isMileage &&
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={t("EnterMileage")}
                    name="mileage"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="PleaseEnterMileage" />,
                      },
                    ]}
                  >
                    <Input size='large' placeholder={t("EnterMileage")} />
                  </Form.Item>
                </Col>
              }
            </>
          }
          {serviceDetails?._id &&
            <Col xs={24} sm={formValues.hssType === "Remote Direct" ? 12 : 24}>
              <Form.Item label={t("Service Hours Info")}>
                <Button type="dashed" size={'large'} style={{ width: '100%', height: '40px' }}>
                  <span>{t("PerDay")}: {serviceDetails?.perday.toFixed(2)}</span>, <span>{t("Used")}: {serviceDetails?.used.toFixed(2)}</span>,
                  <span>{t("Remaining")}: {serviceDetails?.remaining.toFixed(2)}</span>
                </Button>
              </Form.Item>
            </Col>
          }
        </Row>
        {/* } */}
        <Form.Item style={{ float: 'right' }}>
          <Button className='ant-btn-form' size='small' style={{ marginRight: '10px' }} type="primary" danger onClick={() => { handleReset(''); setSelectedClients(new Set()); setServiceDesc(""); }}>
            {t("Reset")}
          </Button>
          <Button disabled={serviceDetails?.remaining === 0} className='ant-btn-form' size='small' type="primary" htmlType="submit" loading={loading}>
            {t("Start")}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default ManualEntry;