import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { LoginOutlined, EyeInvisibleOutlined, EyeTwoTone, ExclamationCircleFilled, FacebookOutlined, TwitterOutlined, DoubleRightOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import AxiosInstance from "../services/axiosInstance";
import { Toaster } from "../utility/Toaster";
import { Layout, Button, Typography, Form, Input, Modal, Select } from "antd";
import Video from "../utility/Video";
import logo from "./../assets/images/logo.png";
import axios from "axios";
const { Title } = Typography;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;
const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    companyId: "",
    clientId:"",
  });
  const [AuthOb, setAuthOb] = useState({});
  const [SlectedCid, SetSlectedCid] = useState('');
  const [SlectedClientId, SetClientId] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#C7C5F4');
  const history = useHistory();
  const [errors, setErrors] = useState({});

  const changeBackgroundColor = () => {
    // const randomNumber = Math.random();
    // const digitsAfterDecimal = parseFloat(randomNumber.toFixed(2).split('.')[1]);
    // console.log(digitsAfterDecimal); // Output the digit just after the decimal point
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16); // Generate random color
    // const randomColor = `#C5CF${digitsAfterDecimal}`;
    // const a = `linear-gradient(90deg, #C7C5F4, ${randomColor})`
    setBackgroundColor(randomColor); // Set the background color
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevstate => ({ ...prevstate, [name]: value }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate email
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
      isValid = false;
    }

    // Validate password
    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Form is valid, you can submit or process the data here
      onFinish(formData);
      // setErrors({});
    } else {
      // Form is not valid, display error messages
    }
  };

  const [changePasswordForm] = Form.useForm();
  useEffect(() => {
    // Clear localStorage when component mounts
    const token = localStorage.getItem("token");
    const pin = localStorage.getItem("pin");
    if (!pin && !token) localStorage.clear();
    else history.replace("/pin");

    const interval = setInterval(changeBackgroundColor, 3000);

    return () => clearInterval(interval); // Cleanup function to clear the interval
  }, []);
  const close = () => {
    localStorage.clear();
    Modal.destroyAll();
  }
  const submitChangePasswordForm = () => {
    if (!changePasswordForm.getFieldValue('oldPassword')) {
      Toaster("error", 'Old password is missing');
      return
    }
    else if (!changePasswordForm.getFieldValue('newPassword')) {
      Toaster("error", 'New password is missing');
      return
    }
    else if (!changePasswordForm.getFieldValue('confirm')) {
      Toaster("error", 'Confirm password is missing');
      return
    }
    const payload = {
      oldPassword: changePasswordForm.getFieldValue('oldPassword'),
      newPassword: changePasswordForm.getFieldValue('newPassword'),
    };
    const endpoint = { e: 'emp/update-password', c: 'client/update-password' };
    const local = endpoint[localStorage.getItem("type") || ""];
    AxiosInstance.put(local, payload)
      .then(({ data }) => {
        if (data.success) {
          Modal.destroyAll();
          history.replace("/pin");
          // Toaster("success", data.message, '');
        } else {
          Toaster("error", data.message, '');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const openChangePasswordModal = () => {
    setLoading(false);
    // const pwdPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/i;
    Modal.confirm({
      title: 'Create your new password so you can login to your account',
      content: <>
        <Form
          layout="vertical"
          form={changePasswordForm}
          name="register"
          onFinish={submitChangePasswordForm}
          style={{
            marginLeft: '-2rem',
            maxWidth: 600,
          }}
          scrollToFirstError
        >

          <Form.Item
            name="oldPassword"
            label="Old Password"

            rules={[
              {
                required: true,
                message: 'Please Enter your old password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password size="small" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: 'Please Enter your new password!',
              },
              // ({getFieldValue}) => ({
              //   validator(_, value) {
              //     if (!value || pwdPattern.test(value)) {
              //       return Promise.resolve();
              //     }
              //     return Promise.reject('Password must contain at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long.');
              //   },
              // }),
            ]}
            hasFeedback
          >
            <Input.Password size="small" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm New Password"
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your new password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password size="small" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>
          {/* <Form.Item>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item> */}
        </Form>
      </>,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <Button type='primary' danger className="ant-btn-modal" onClick={() => close()}>Close</Button>
          <Button className="ant-btn-modal" type='primary' onClick={() => submitChangePasswordForm()}>Okay</Button>
          {/* <OkBtn /> */}
        </>
      ),
    });
  }

  const onFinish = (values) => {
    setLoading(true);
    const loginPayload = {
      email: values.email,
      password: values.password,
      appType: "web"
    };

    if (SlectedCid !== "") {
      loginPayload.companyId = SlectedCid;
    }
    if (SlectedClientId !== "") {
      loginPayload.clientId = SlectedClientId;
    }
    AxiosInstance.post("evv-login", loginPayload)
      .then(({ data }) => {
        if (data.success) {
          if (data.message == 'You are registered with multiple companies. Please select a company to login') {
            const array = data.data
            showCompaniesPrompt(values, array);
            return false;
          }
          if (data.message == 'You are registered with multiple clients. Please select a client to login') {
            const array = data.data
            showRPSPrompt(values, array);
            return false;
          }
          const { data: userData } = data;
          const token = userData.token;
          if (userData.type === "r") {
            userData.type = "c";
            userData.rType = "rp";
          }
          localStorage.setItem("token", token);
          localStorage.setItem("evvUrl", data.evvUrl);
          localStorage.setItem("chatUrl", data.chatUrl);
          localStorage.setItem("dirtyBit", data.dirtyBit);
          localStorage.setItem('type', userData.type);
          localStorage.setItem("USER_INFO", JSON.stringify(userData));
          if (userData.rType)
            localStorage.setItem('rType', userData.rType);
          localStorage.setItem("state", userData.companyId.state);
          if (userData?.configurationEvvNew) {
            const filteredMenu = userData.configurationEvvNew.menu.filter((x => x.value === true));
            localStorage.setItem("Menus", JSON.stringify(filteredMenu));
            localStorage.setItem("Home_Screen", JSON.stringify(userData.configurationEvvNew.conditional.home));
            localStorage.setItem("History_Screen", JSON.stringify(userData.configurationEvvNew.conditional.history));
            localStorage.setItem("payrolGracePeriod", JSON.stringify(userData.configurationEvvNew?.payrolGracePeriod) || 0);
            localStorage.setItem("isAllowedPayrollGracePeriod", JSON.stringify(userData.configurationEvvNew?.isAllowedPayrollGracePeriod) || false);
            window.dispatchEvent(new Event("storage"));
          }
          setAuthToken(token);
          // Toaster("success", data.message, '');
          if (userData.firstLogin && userData?.rType !== "rp") {
            openChangePasswordModal();
           return;
         }
         else history.push("/pin");
        } else {
          console.log(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        // Toaster('error', 'Error', err.response.data.message);
        setLoading(false);
      });
  };



  useEffect(() => {
    if (SlectedCid) {
      onFinish(AuthOb)
    }
    Modal.destroyAll();
  }, [SlectedCid]); // Dependency array

  useEffect(() => {
    if (SlectedClientId) {
      onFinish(AuthOb)
    }
    Modal.destroyAll();
  }, [SlectedClientId]); // Dependency array

  const handleChangeCompany = (value, field) => {
    if (!value) return;
    SetSlectedCid(value)
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleChangeRP = (value, field) => {
    if (!value) return;
    SetClientId(value)
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const showCompaniesPrompt = (obj, CompanyList) => {
    setAuthOb(obj)
    confirm({
      title: 'Select the company to proceed',
      icon: <ExclamationCircleFilled />,
      footer: null,
      content: (
        <div>
          <Select
            placeholder="Select a company"
            style={{ width: "100%", marginLeft: "-10px" }}
            onChange={(value) => handleChangeCompany(value, "companyId")}
          >
            {CompanyList.map((option) => (
              <Option key={option._id} value={option.companyId._id}>
                {option.companyId.sName}
              </Option>
            ))}
          </Select>
        </div>
      ),
    });
  };

  const showRPSPrompt = (obj, ClientList) => {
    setAuthOb(obj)
    debugger
    confirm({
      title: 'Select a Client to proceed',
      icon: <ExclamationCircleFilled />,
      footer: null,
      content: (
        <div>
          <Select
            placeholder="Select a Client"
            style={{ width: "100%", marginLeft: "-10px" }}
            onChange={(value) => handleChangeRP(value, "clientId")}
          >
            {ClientList.map((option) => (
              <Option key={option._id} value={option.client_id._id}>
                {option.client_id?.fullname}
              </Option>
            ))}
          </Select>
        </div>
      ),
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const setAuthToken = (token) => {
    if (token) {
      AxiosInstance.defaults.headers.common["Token"] = token;
    } else delete AxiosInstance.defaults.headers.common["Token"];
  };

  return (
    <>
      <Video />
      <Layout
        className="layout-default layout-signin"
        style={{ position: "relative" }}
      >
        <Content className="signin text-center">
          <img
            alt="logo"
            className="center"
            style={{ width: "160px" }}
            src={logo}
          />
          <Title style={{ margin: 0, textAlign: "center" }} underline level={2}>
            RevivingCare
          </Title>
          <Title
            style={{ marginTop: 4, textAlign: "center" ,opacity:0.7 }}
            underline
            strong
            level={4}
          >
            Reimagined Homecare Software
          </Title>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Form.Item
              className="username"
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "The email is not valid Email!",
                },
                {
                  required: true,
                  message: "Please enter your Email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item

              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input.Password placeholder="Password" className="sign-input" />
            </Form.Item>

            <Form.Item>
              <Button
                icon={<LoginOutlined />}
                loading={loading}
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                SIGN IN
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
















      {/* <div className="sign_container" style={{ backgroundColor }}>
        <div className="sign_screen">
          <div className="screen__content">
            <form className="login" onSubmit={handleSubmit}>
              <div className="login__field">
                <i className="login__icon"><UserOutlined /></i>
                <input type="text" name="email" value={formData.email} onChange={handleInputChange} className="login__input" placeholder="Email" />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>
              <div className="login__field">
                <i className="login__icon"><LockOutlined /></i>
                <input type="password" name="password" value={formData.password} onChange={handleInputChange} className="login__input" placeholder="Password" />
                {errors.password && <div className="error">{errors.password}</div>}
              </div>
              <button type="submit" className="button login__submit">
                <span className="button__text">{!loading ? 'Log In Now' : 'Loading ...'}</span>
                <i className="button__icon"><DoubleRightOutlined /></i>
              </button>
            </form>
            <div className="social-login">
              <h3 style={{marginTop:'5px',color:'#fff'}}>Connect via</h3>
              <div className="social-icons">
                <a href="#" className="social-login__icon"> <InstagramOutlined /></a>
                <a href="#" className="social-login__icon"><FacebookOutlined /></a>
                <a href="#" className="social-login__icon"><TwitterOutlined /></a>
              </div>
            </div>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default SignIn;
